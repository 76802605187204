<template>
  <div class="changeGoods">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/stock' }">库存管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/stock' }">库存列表</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="changeGoods-content">
      <el-form
        :model="addGoodsFrom"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
       <el-form-item
          label="门店名称"
          prop="shop_id"
        >
          <el-select
            v-model="addGoodsFrom.shop_id"
            placeholder="请选择超市名称"
            style="width: 440px"
            size="small"
            @change="gettypelist"
            disabled
          >
            <el-option
              :label="shopName"
              :value="addGoodsFrom.shop_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="商品分类"
            prop="category_id"
            :rules="[{ required: true, message: '商品分类不能为空' }]"
          >
            <el-select
              v-model="addGoodsFrom.category_id"
              placeholder="请选择商品分类"
              style="width: 440px"
              size="small"
            >
              <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="商品名称"
            prop="good_name"
            :rules="[{ required: true, message: '商品名称不能为空' }]"
          >
            <el-input
              size="small"
              v-model.number="addGoodsFrom.good_name"
              autocomplete="off"
              placeholder="请选择商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="条形码编号"
            prop="good_code"
            :rules="[{ required: true, message: '商品条形码编号不能为空' }]"
          >
            <el-input
              size="small"
              v-model.number="addGoodsFrom.good_code"
              autocomplete="off"
              placeholder="请输入条形码编号"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="商品原价"
            prop="good_price"
            :rules="[{ required: true, message: '商品原价不能为空' }]"
          >
            <el-input
              size="small"
              v-model.number="addGoodsFrom.good_price"
              autocomplete="off"
              placeholder="请输入商品原价"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="商品数量"
            prop="good_quantity"
            :rules="[{ required: true, message: '商品数量不能为空' }]"
          >
            <el-input
              size="small"
              v-model.number="addGoodsFrom.good_quantity"
              autocomplete="off"
              placeholder="请输入商品数量"
            ></el-input>
          </el-form-item>
        <el-form-item style="display: flex; justify-content: center">
          <el-button
            type="primary"
            size="small"
            style="background: #cd0550; border: none"
            @click="changeGoods"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local.js"
import { goodSeriesCategory,supermarketchainGoodchange} from "@/api/shop.js"

export default {
  data() {
    return {
      addGoodsFrom: {
        shop_id:'',
        category_id: "",
        good_name: "",
        good_code: "",
        good_price: "",
        good_quantity: "",
      },
      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
      },
      tk: "",
      imageUrl: "",
      typeList:'',
      dialogVisible:false,
      shopName:''
    }
  },
  methods: {
      //获取超市列表
    chainGoodshopList() {
      chainGoodshopList_api().then((res) => {
        this.shopList = res.data.data
      })
    },
    //预览图片
    bigImg() {
      this.dialogVisible = true
    },
    //文件上传成功
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data
    },
    //上传文件之前的钩子
    beforeAvatarUpload(file) {
      const isLt500k = file.size / 1024 < 500
      if (!isLt500k) {
        this.$message.error("上传头像图片大小不能超过 500kb!")
      }
      return isLt500k
    },
    //修改商品信息
    async changeGoods() {
      let res = await supermarketchainGoodchange({
        id:this.goodsInfo.id,
        shop_id:this.addGoodsFrom.shop_id,
        category_id: this.addGoodsFrom.category_id,
        good_name: this.addGoodsFrom.good_name,
        good_code: this.addGoodsFrom.good_code,
        good_price:this.addGoodsFrom.good_price,
        good_quantity:this.addGoodsFrom.good_quantity
      })
      if (res.data.code == 0) {
        this.$message({
          message: "修改成功!",
          type: "success",
        })
        this.$router.push("/stock")
      }
    },
    gettypelist(e) {
      goodSeriesCategory({ shop_id: e }).then((res) => {
        this.typeList = res.data.data
      })
    },
  },
  created() {
    this.tk = local.get("tk")
     let goodsInfoA = local.get("goodsInfo")
    this.goodsInfo = JSON.parse(goodsInfoA)
    this.goodsInfo.id=this.goodsInfo.id
    this.addGoodsFrom.good_name = this.goodsInfo.good_name
    this.addGoodsFrom.shop_id = this.goodsInfo.shop_id
    this.addGoodsFrom.category_id = this.goodsInfo.category.id
    this.addGoodsFrom.good_code= this.goodsInfo.good_code
    this.addGoodsFrom.good_price = this.goodsInfo.good_price
    this.addGoodsFrom.good_quantity = this.goodsInfo.good_quantity
    this.shopName=this.goodsInfo.shop.name
    goodSeriesCategory({ shop_id:this.addGoodsFrom.shop_id }).then((res) => {
      this.typeList = res.data.data
    })
  },
}
</script>

<style lang="less" scoped>
.changeGoods {
  width: 100%;
  height: 100%;
  background: #eee;
  padding: 30px;
  box-sizing: border-box;
  .changeGoods-content {
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
}
.avatar-uploader {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  width: 346px;
  height: 160px;
}
.el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 346px;
  height: 160px;
  line-height: 160px;
  text-align: center;
}
.avatar {
  width: 346px;
  height: 160px;
  display: block;
}
.imgTips {
  color: #cd0550;
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
  p {
    line-height: 26px;
  }
}
.seeImg {
  cursor: pointer;
  width: 70px;
  height: 36px;
  background: #cd0550;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
}
</style>